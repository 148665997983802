.sizechart-container {
  overflow-x: auto;
  table {
    width: auto;
    min-width: 100%; 
    border-collapse: collapse; 

    td {
      text-align: center;
      padding: 12px 0px !important;
      white-space: nowrap; 
    }

    .radio-column input[type='radio']:checked {
      accent-color: #FF3F6C !important;
      border: 1px solid #707070;
    }

    .bold {
      font-weight: bold;
      padding: 20px !important;
    }
  }

  .title {
    // font-size: 16px !important;
    // font-weight: 600 !important;
    padding: 15.5px 0px 15.5px 20px !important;
    margin: 0px !important;
  }

  .ant-switch {
    min-width: 49px !important;
    height: 28px !important;
    background-color: white !important;
    border: 1px solid #E4E4E4 !important;
    color: black;
  }

  .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    color: black;
    text-align: center;
    font-size: 14px;
  }

  .ant-switch.ant-switch-checked {
    background: none !important;
  }

  .ant-switch .ant-switch-handle::before {
    border-radius: 17px !important;
    width: 40px !important;
    height: 24px !important;
    background-color: black !important;
    left: -20px !important;
    content: "in";
    color: white;
    text-align: center;
  }

  .table-container {
    // border-style: solid;
    // border-color: #e4e4e4;
  }
}
body .radio-cell {
  margin-left: 10px !important;
  margin-right: -5px;
}